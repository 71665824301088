/* eslint-disable max-nested-callbacks */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IPlaylist,
  IPlaylistItem,
  useSendVideoPlayerPositionMutation,
  useSetNextPlaylistItemMutation,
} from '../../../redux/platformApi';
import { PlaylistType } from '../../../redux/platformApi/enums';
import { selectIsAltView } from '../../../redux/slices/auth';
import { useUpdateStreamingViewMutation } from '../../../redux/streamGoApi';
import { StreamingView } from '../../../redux/streamGoApi/enums';

interface IUsePlaylist {
  defaultView?: StreamingView;
  eventId?: number;
  playlist?: IPlaylist;
  video?: HTMLVideoElement | null;
}

export const usePlaylist = ({
  defaultView,
  eventId,
  playlist,
  video,
}: IUsePlaylist) => {
  const isAltView = useSelector(selectIsAltView);
  const [updateStreamingView] = useUpdateStreamingViewMutation();
  const [setNextPlaylistItem] = useSetNextPlaylistItemMutation();
  const [sendVideoPlayerPosition] = useSendVideoPlayerPositionMutation();
  const [isMediaMode, setIsMediaMode] = useState<IPlaylistItem>();
  const [updateState, setUpdateState] = useState<boolean>(false);

  useEffect(() => {
    const isMedia =
      playlist && playlist?.currentPlaylistItemId && playlist?.items
        ? playlist?.items.find(
            x =>
              x.data.id === playlist.currentPlaylistItemId &&
              x.data.type.id === PlaylistType.Media &&
              x.links.pre_record,
          )
        : undefined;

    setIsMediaMode(isMedia);
  }, [playlist]);

  const nextPlaylistItem = useCallback(() => {
    if (playlist && eventId && !isAltView) {
      const { currentPlaylistItemId, items } = playlist;

      setNextPlaylistItem();

      if (
        items.length > 0 &&
        items[items.length - 1].data.id === currentPlaylistItemId
      ) {
        updateStreamingView({
          roomId: eventId.toString(),
          StreamingViewAlt: StreamingView.Unset,
          StreamingViewDefault: StreamingView.Unset,
          StreamingView: StreamingView.PostRoll,
        });
        return;
      }

      updateStreamingView({
        roomId: eventId.toString(),
        StreamingViewAlt: StreamingView.Unset,
        StreamingViewDefault: StreamingView.Unset,
        StreamingView: defaultView,
      });
    }
  }, [
    defaultView,
    eventId,
    isAltView,
    playlist,
    setNextPlaylistItem,
    updateStreamingView,
  ]);

  const getNextPollInterval = (position: number): number => {
    if (position > 5 * 60 * 1000) {
      return 60; // 1 minute
    }
    if (position > 69 * 1000) {
      return 10; // 10 seconds
    }
    if (position > 1000) {
      return 1; // 1 second
    }
    return 0.3; // 300 milliseconds
  };

  const pollDuration = useCallback(() => {
    if (video && playlist?.currentPlaylistItemId && !isAltView) {
      const remainingTimeMs = (video.duration - video.currentTime) * 1000;
      const position = Math.round(remainingTimeMs);

      sendVideoPlayerPosition({
        playlistId: playlist.currentPlaylistItemId,
        pos: position,
      });

      if (position > 0) {
        const nextPollInterval = getNextPollInterval(position);
        window.setTimeout(() => {
          setUpdateState(prev => !prev);
        }, nextPollInterval * 1000);
      }
    }
  }, [
    isAltView,
    playlist?.currentPlaylistItemId,
    sendVideoPlayerPosition,
    video,
  ]);

  const onPlaying = useCallback(() => {
    if (!video || !video.duration) {
      setTimeout(() => onPlaying(), 200);
    } else {
      pollDuration();
    }
  }, [pollDuration, video]);

  useEffect(() => {
    pollDuration();
  }, [pollDuration, updateState]);

  return {
    isMediaMode,
    onPlaying,
    nextPlaylistItem,
  };
};
