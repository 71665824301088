/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StreamingViewTiles: any = styled('div')({
  height: '100%',
  overflow: 'auto',
});

export const StreamingViewContainer: any = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '3%',
  justifyContent: 'center',
});

export interface IStreamingViewItem {
  selected?: boolean;
}

export const StreamingViewItem: any = styled(Box)(
  ({ selected }: IStreamingViewItem) => ({
    flexDirection: 'column',
    flexBasis: '30%',
    flexWrap: 'wrap',
    textAlign: 'center',
    fontSize: '0.8em',
    border: selected ? '3px solid rgb(0, 175, 239)' : '3px solid #373737',
    background: '#14151d',
    color: 'white',
    fontWeight: 'bold',
    marginBottom: '10px',
    cursor: 'pointer',
    filter: selected ? 'brightness(1.05)' : 'none',
    '&:hover': {
      border: '3px solid rgb(0, 175, 239)',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  }),
);

export const StreamingViewTitle: any = styled(Box)({
  display: 'block',
  textAlign: 'center',
  margin: '10px',
  fontWeight: 'bold',
  fontSize: '1.1em',
  color: 'white',
});

export const StreamingViewMainTitle: any = styled(StreamingViewTitle)({
  fontSize: '1.5em',
  paddingBottom: '10px',
  color: '#44c0b9',
});
