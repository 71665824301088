import {
  StreamingView,
  StreamingViewType,
} from '../../../redux/streamGoApi/enums';
import {
  otherViews,
  screenshareViews,
  slidesViews,
  streamingViews,
  webcamViews,
} from '../constants';
import { IStreamingViewOptions } from '../types';
import { getStreamingViews } from './getStreamingViews';

interface IGetStreamingViewOptions {
  selectedStreamingView?: number;
  setSelectedStreamingView: (view: number) => void;
  streamingViewOptions: IStreamingViewOptions;
  streamingViewType: StreamingViewType;
  width: number;
}

export const getStreamingViewOptions = ({
  selectedStreamingView,
  setSelectedStreamingView,
  streamingViewOptions,
  width,
}: IGetStreamingViewOptions) => {
  const { hasPPT, hasScreenShare, hasSgVm } = streamingViewOptions;
  const screenShareEnabled = hasScreenShare;

  let streamingViewsFiltered = Object.values(streamingViews);

  streamingViewsFiltered =
    hasPPT || hasSgVm
      ? streamingViewsFiltered
      : streamingViewsFiltered.filter(x => !slidesViews.find(y => y === x));

  streamingViewsFiltered = screenShareEnabled
    ? streamingViewsFiltered
    : streamingViewsFiltered.filter(x => !screenshareViews.find(y => y === x));

  if (!hasPPT && !hasSgVm) {
    streamingViewsFiltered = streamingViewsFiltered.filter(
      x => !slidesViews.find(y => y === x),
    );
  }

  const getViewSection = (sectionViews: StreamingView[]) =>
    getStreamingViews({
      sectionViews,
      filteredViews: streamingViewsFiltered,
      width,
      setSelectedStreamingView,
      selectedStreamingView,
    });

  const webcamView = getViewSection(webcamViews);
  const slideView = getViewSection(slidesViews);
  const screenshareView = getViewSection(screenshareViews);
  const otherView = getViewSection(otherViews);

  return { webcamView, slideView, screenshareView, otherView };
};
