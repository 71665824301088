/* eslint-disable @next/next/no-img-element */
import { useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { streamingViewsDesc, streamingViewsImage } from './constants';
import { StreamingViewItem } from './StreamingViewComponents';

const getFlexBasis = (width: number) => {
  if (width > 750) {
    return '22%';
  }
  if (width > 470) {
    return '31%';
  }
  return '48%';
};

interface IStreamingView {
  selectedStreamingView?: number;
  setSelectedStreamingView: (view: number) => void;
  streamingView: number;
  width: number;
}

export const StreamingView = ({
  selectedStreamingView: selected,
  setSelectedStreamingView,
  streamingView,
  width,
}: IStreamingView) => {
  const handleSetStreamingView = useCallback(
    (view: number) => () => setSelectedStreamingView(view),
    [setSelectedStreamingView],
  );

  return (
    <Tooltip title={streamingViewsDesc[streamingView]}>
      <StreamingViewItem
        onClick={handleSetStreamingView(streamingView)}
        selected={selected === streamingView}
        style={{
          flexBasis: getFlexBasis(width),
        }}
      >
        <img
          alt={streamingViewsDesc[streamingView]}
          src={streamingViewsImage[streamingView]}
        />
      </StreamingViewItem>
    </Tooltip>
  );
};
