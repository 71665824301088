/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  StreamingView,
  StreamingViewType,
} from '../../redux/streamGoApi/enums';
import { ConfirmationDialog } from '../Form';
import {
  streamingViewTitle,
  streamingViewsDesc,
  streamingViewsImage,
} from './constants';

export const StreamingViewConfirm: any = styled(Box)`
  ${({ theme }) => `
    div {
      padding: 10px 0; 
      > img {
       max-width: 100%;
      }
    }

    b {
      color: ${theme.palette.primary.main}
    }

    span div {
      color: white;
      background: #d3302f;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      > svg {
        margin-right: 8px;
      }
    }
  `}
`;

export const Bold: any = styled('b')({
  color: 'white!important',
});

export interface IStreamingViewConfirmation {
  handleCancel: () => void;
  handleConfirm: () => void;
  open: boolean;
  proposedStreamingView: StreamingView | undefined;
  streamingViewType: StreamingViewType;
}

export const StreamingViewConfirmation = ({
  handleCancel,
  handleConfirm,
  open,
  proposedStreamingView,
  streamingViewType,
}: IStreamingViewConfirmation) => {
  return (
    <ConfirmationDialog
      keepMounted={true}
      okButtonText="Yes - I'm sure"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      open={open}
      title="Are you sure?"
    >
      <StreamingViewConfirm>
        <div>
          Are you sure you want to change{' '}
          {streamingViewTitle[streamingViewType]} to <br />
          <Bold>{streamingViewsDesc[Number(proposedStreamingView)]}</Bold>?
        </div>
        <div>
          <img
            alt={streamingViewsDesc[Number(proposedStreamingView)]}
            src={streamingViewsImage[Number(proposedStreamingView)]}
          />
        </div>
        {streamingViewType === StreamingViewType.StreamingView ? (
          <span>
            <div>Warning: This will change the streaming view immediately</div>
          </span>
        ) : null}
      </StreamingViewConfirm>
    </ConfirmationDialog>
  );
};
