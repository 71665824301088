import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import { selectIsModerator } from '../../redux/slices/auth';
import {
  IRoom,
  useGetUserQuery,
  useUpdateStreamingViewMutation,
} from '../../redux/streamGoApi';
import {
  StreamingView,
  StreamingViewType,
  UserRole,
} from '../../redux/streamGoApi/enums';
import {
  StreamingViewContainer,
  StreamingViewTiles,
  StreamingViewTitle,
} from './StreamingViewComponents';
import { StreamingViewConfirmation } from './StreamingViewConfirmation';
import { IStreamingViewOptions } from './types';
import { getStreamingViewOptions } from './utils';

interface IStreamingViews {
  roomDetails?: IRoom;
  roomId: string;
  streamingViewOptions: IStreamingViewOptions;
  streamingViewType: StreamingViewType;
}

export const StreamingViews = ({
  roomDetails,
  roomId,
  streamingViewOptions,
  streamingViewType,
}: IStreamingViews) => {
  const isModerator = useSelector(selectIsModerator);
  const { data: user } = useGetUserQuery();
  const [windowWidth, setWindowWidth] = useState(750);
  const { ref, width = 750 } = useResizeDetector();

  useEffect(() => {
    const clientWidth = ref?.current?.clientWidth;
    if (clientWidth) {
      setWindowWidth(clientWidth);
    }
  }, [ref, width]);

  const [selectedStreamingView, setSelectedStreamingView] =
    useState<StreamingView>();
  const [proposedStreamingView, setProposedStreamingView] =
    useState<StreamingView>();
  const [updateStreamingView] = useUpdateStreamingViewMutation();
  const currentView = roomDetails?.[streamingViewType];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentView) {
      setSelectedStreamingView(prev =>
        prev !== currentView ? currentView : prev,
      );
    }
  }, [currentView]);

  const handleUpdateStreamingView = useCallback(
    (newStreamingView: StreamingView) => {
      if (newStreamingView !== selectedStreamingView) {
        setSelectedStreamingView(newStreamingView);
        updateStreamingView({
          roomId,
          StreamingViewAlt: roomDetails?.StreamingViewAlt,
          StreamingView: roomDetails?.StreamingView,
          [streamingViewType]: newStreamingView,
        });
      }
    },
    [
      roomDetails?.StreamingView,
      roomDetails?.StreamingViewAlt,
      roomId,
      selectedStreamingView,
      streamingViewType,
      updateStreamingView,
    ],
  );

  const handleSetOpen = useCallback((newStreamingView: StreamingView) => {
    setProposedStreamingView(newStreamingView);
    setOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    if (proposedStreamingView) {
      handleUpdateStreamingView(proposedStreamingView);
    }
  }, [handleUpdateStreamingView, proposedStreamingView]);

  const { otherView, screenshareView, slideView, webcamView } =
    getStreamingViewOptions({
      setSelectedStreamingView: handleSetOpen,
      selectedStreamingView,
      width: windowWidth,
      streamingViewOptions,
      streamingViewType,
    });

  return user?.Role !== UserRole.User && isModerator ? (
    <>
      <StreamingViewTiles ref={ref}>
        <StreamingViewTitle>Webcam Views</StreamingViewTitle>
        <StreamingViewContainer>{webcamView}</StreamingViewContainer>
        {slideView.length ? (
          <>
            <StreamingViewTitle>Slide Views</StreamingViewTitle>
            <StreamingViewContainer>{slideView}</StreamingViewContainer>
          </>
        ) : null}
        {screenshareView.length ? (
          <>
            <StreamingViewTitle>Screenshare Views</StreamingViewTitle>
            <StreamingViewContainer>{screenshareView}</StreamingViewContainer>
          </>
        ) : null}
        <StreamingViewTitle>Other Views</StreamingViewTitle>
        <StreamingViewContainer>{otherView}</StreamingViewContainer>
      </StreamingViewTiles>
      <StreamingViewConfirmation
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        open={open}
        proposedStreamingView={proposedStreamingView}
        streamingViewType={streamingViewType}
      />
    </>
  ) : null;
};
